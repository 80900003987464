<template>
  <div class="abort-container">
    <div class="introduce">
      <h2 class="title">简介</h2>
      <div class="content">
        {{ introduce }}
      </div>
    </div>
    <div class="sreenshot">
      <h2 class="title">产品截图</h2>
      <div class="body">
        <img src="../assets/1.png" alt="">
        <img src="../assets/2.png" alt="">
        <img src="../assets/3.png" alt="">
        <img src="../assets/4.png" alt="">
        <img src="../assets/5.png" alt="">
        <img src="../assets/5.1.png" alt="">
        <img src="../assets/6.png" alt="">
        <img src="../assets/7.png" alt="">
        <img src="../assets/9.png" alt="">
        <img src="../assets/10.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      introduce: '云海易购商城APP，引导线上消费者到线下消费，促进线上线下共同发展。 云海易购商城APP，将企业的宣传、营销、客户、内部管理等有机统一， 多店铺的连锁，企业通过线上线下云海易购商城购物， 用户可直接触达，可以亲身体验一下，在线消费等等。 【品牌特卖质量保证】 全场大牌和实体品牌在线深度折扣，限时特卖，百分百正品保障； 服饰、美妆、电器多种品类，线下商务与互联网结合在一起的购物平台： 【海量折扣卡券，享受折上折活动】 每天限时，线上和线下实体店送折扣卡券 每天早10点＆晚8点上新； 【购物体验实力保障】 人工客服天天在线，每天9:00~24:00贴心在线为你服务； 7天无理由退货，自营商品免费上门收件; 还有自营商品满88元包邮，全年无限次！ 【快递速达安全到货】 快递由顺丰承运，全力速达，急你所急； 【意见反馈】 '
    };
  },
  computed: {

  },
  methods: {

  },
  created() {

  },
  mounted() {

  },
  watch: {

  },
};
</script>

<style scoped>
.introduce {
  width: 900px;
  margin-top: 10px;
  margin-left: 20px;
}

.introduce .title {
  margin-bottom: 20px;
}

.sreenshot {
  margin-top: 30px;
  margin-left: 20px;
}

.sreenshot .body {
  margin-top: 30px;
}

.sreenshot .body img {
  width: 180px;
  margin-right: 50px;
  margin-bottom: 50px;
}
</style>
