import vue from 'vue'
import vueRouter from 'vue-router'

import Abort from '../view/abort.vue'
import Index from '../view/index.vue'

vue.use(vueRouter)

const router = new vueRouter({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      component: Index
    },
    {
      path: '/abort',
      component: Abort
    }
  ]
})

export default router