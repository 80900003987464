<template>
  <div>
    <h1 class="company-name">
      <a href="javascript:;">
        <img src="../assets/logo.jpg" alt="">
        御魂酒祖(厦门)控股有限公司
      </a>
      <router-link class="abort" to="/abort">关于云海易购APP</router-link>
    </h1>
    <img src="https://www.fypay-xm.com/images/banner/1.jpg" alt="">
    <img src="https://www.fypay-xm.com/images/banner/4.jpg" alt="">
    <img src="https://www.fypay-xm.com/images/banner/5.jpg" alt="">
    <div class="main-right">
      Copyright @2024 御魂酒祖(厦门)控股有限公司 版权所有 <a href="https://beian.miit.gov.cn/"> 闽ICP备2023008954号-1 </a>
      座 机：400-8513-799
      邮 箱： 1249061798@qq.com
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  created() {

  },
  mounted() {

  },
  watch: {

  },
};
</script>

<style scoped>
.main-right {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #a2a1a1;
}

.main-right>a {
  text-decoration: none;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
}

.main-right>a:hover {
  color: blue;
  text-decoration: none;
  font-size: 17px;
}
</style>
