<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
 * {
   margin: 0;
   padding: 0;
 }
 body,html {
   margin: 0;
   padding: 0;
 }
 img {
   vertical-align: middle;
 }
 
a {
    text-decoration: none;
    color: #333;
    font-size: 30px;
}
a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}
/* 公司名称 */
.company-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-name .abort {
  margin-right: 50px;
  font-size: 20px;
  font-weight: 400;
}
</style>
